const linkToAnchorNavegation = () => {
  const anchor_js = new URL(`${window.location.href}`).searchParams.get('anchor_js');
  if (anchor_js){
    setTimeout(function() {
      $('html, body').animate({ 
        scrollTop: $( `#${anchor_js}` ).offset().top - 150
      }, 1000);
    }, 100)
  }
}

export default linkToAnchorNavegation
//export const linkToAnchor = () => {
//   $('.anchor').unbind().click( function(event){
//     event.preventDefault();
//     $('html, body').animate({ 
//       scrollTop: $( $( this ).data('target') ).offset().top - 150
//     }, 1000);
//   })
// }