export const initDefaultCarousel = () => {
  if ( !$('.slick-carousel').hasClass('slick-initialized') ){
    $('.slick-carousel').slick({
      infinite: false,
      dots: true,
      arrows: false,
      slidesToShow: 3,

      responsive: [
        {
          // TABLET 
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          }
        }, {
          // CELULAR
          breakpoint: 770,
          settings: {
            slidesToShow: 1
          }
        },
      ]
    });
  }
}

export const initCarouselProperties = () => {
  if ( !$('.slick-carousel-properties').hasClass('slick-initialized') ){
    $('.slick-carousel-properties').slick({
      infinite: false,
      dots: true,
      arrows: false,
      slidesToShow: 3,

      responsive: [
        {
          // TABLET 
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          }
        }, {
          // CELULAR
          breakpoint: 770,
          settings: {
            slidesToShow: 1
          }
        },
      ]
    });
  }
}

export const initCarouselArticles = () => {
  if ( !$('.slick-carousel-articles').hasClass('slick-initialized') ){
    $('.slick-carousel-articles').slick({
      infinite: false,
      dots: true,
      arrows: false,
      slidesToShow: 4,

      responsive: [
        {
          // TABLET 
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          }
        }, {
          // CELULAR
          breakpoint: 770,
          settings: {
            slidesToShow: 1
          }
        },
      ]
    });
  }
}

export const initCarouselImagesBanner = () => {
  if ( !$("#slick-carousel-images-banner").hasClass('slick-initialized') ){
    $('#slick-carousel-images-banner').slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      prevArrow: '<i class="slick-btns prev fullscreen material-icons"> keyboard_arrow_left </i>',
      nextArrow: '<i class="slick-btns next fullscreen material-icons"> keyboard_arrow_right </i>',

      responsive: [
        {
          // TABLET
          breakpoint: 992,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
          }
        },
        {
          // CELULAR
          breakpoint: 770,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          }
        }
      ]
    });
  }
}

export const destroyCarousel = () => {
  $('.slick-carousel').slick('unslick');
  $('.slick-carousel-properties').slick('unslick');
  $('.slick-carousel-articles').slick('unslick');
  $('#slick-carousel-images-banner').slick('unslick');
}
