import cleaningObject from './utils/cleaning-object';
import replaceFilter from './utils/replace-filter';
import redirectToPath from './utils/redirect-to-path';

export const filterBlog = () => {
  // Eventos personalizados
  $("#blog-filter .category").unbind().click(ajaxBlog);
  $("#filter-blog").change( ajaxBlog );

  // Impede comportamento padrão
  $("#filter-blog").submit( event => {
    event.preventDefault();
    event.stopPropagation();
  });
}

const ajaxBlog = event => {
  event.preventDefault();
  event.stopPropagation();

  let partialFilterPath = $('#blog-filter').data('to-path');
  let linkCategory = $(event.currentTarget);

  var data = {
    search: $('#filter-blog-search').val().replace(' ', '-'),
    category: linkCategory.hasClass('category') ? linkCategory.data('category') : null
  }

  $.ajax({
    url: '/ajax/filter-articles',
    method: 'get',
    dataType: 'script',
    data: data,
    beforeSend: () => {
      $("#list-launches").hide();
      $("#loader").show();
    },
    success: () => {
      cleaningObject(data);
      $("#loader").hide();
      $("#list-launches").show();
    }

  }).done(() => {
    if (window.location.pathname != partialFilterPath)
      redirectToPath(partialFilterPath, data);
    else 
      replaceFilter(partialFilterPath, data);
  })
}