// S - Sticky Navbar
export const initStickyNavbar = (smallHeader, nav) => {
  let parentDiv = nav.parent().closest('div');
  parentDiv.height(nav.height());

  $(window).on("scroll", function(){  
    if ($(this).scrollTop() > smallHeader.outerHeight()) {
        nav.addClass("fixed-top");
        // add padding top to show content behind navbar
        $('body').css('padding-top', nav.outerHeight() + 'px');
      } else{
        nav.removeClass("fixed-top");
        // remove padding top from body
        $('body').css('padding-top', '0');
      }   
  });
}
// E - Sticky Navbar