import OnScreen from 'onscreen';
import cleaningObject from './utils/cleaning-object';
import replaceFilter from './utils/replace-filter';
import redirectToPath from './utils/redirect-to-path';
import getUrlParams from './utils/get-url-params';
import scrollToElement from './utils/scroll-to-element'

const changeForm = '#change_properties_filter';
const submitForm = '#submit_properties_filter';

const onScreen = new OnScreen({
  tolerance: 0,
  debounce: 0,
  container: window
});

export const changePropertiesFilter = () => {
  $(`${changeForm}, #order`).change( event => {
    event.preventDefault();
    event.stopPropagation();

    $('#page').val(1);
    filterProperty();
  })
  $(changeForm).submit( event => {
    event.preventDefault();
    event.stopPropagation();
  } )
}

export const submitPropertiesFilter = () => {
  $(submitForm).submit( event => {
    event.preventDefault();
    event.stopPropagation();

    $('#page').val(1);
    filterProperty();
    scrollToElement('#nav-tab, #section-launches', 100, 1000);
  })
}

export const tabPropertiesFilter = () => {
  $('a[data-toggle="tab"]').on('shown.bs.tab', event => {
    event.preventDefault();
    event.stopPropagation();

    $('#page').val(1);
    filterProperty();
  })
}

export const paginationPropertiesFilter = () => {
  $('.properties-pagination a, .launches-pagination a').click( event => {
    event.preventDefault();
    event.stopPropagation();

    const href = $(event.currentTarget).attr('href')
    const page = getUrlParams( href, 'page');
    $('#page').val(page);

    filterProperty();
    scrollToElement('#nav-tab, #section-launches', 100, 1000);
  })
}

export const infinitePaginationPropertiesFilter = () => {
	onScreen.on('enter', '.infinite-scroll', element => {
    
    var hasNextPage = $('.pagination .next_page').attr('href');
    var hasScrolling = $(element).hasClass('loading');  
    
    if ( hasNextPage && !hasScrolling ){
      $(element).addClass('loading');

      const page = getUrlParams( hasNextPage, 'page');
      $('#page').val(page);
      filterProperty();
    }
	});
}

const filterProperty = () => {
  const toPath =     $(`${changeForm}, ${submitForm}`).data('toPath');
  const filterPath = $(`${changeForm}, ${submitForm}`).data('filterPath');
  var data = getValueFromFilterInputs();
  cleaningObject(data);

  $.ajax({
    url: filterPath,
    method: 'get',
    dataType: 'script',
    data: data
  }).done( () => {
    toPath ? redirectToPath(toPath, data) : replaceFilter(toPath, data);
  })
}

const getValueFromFilterInputs = () => {
  var data = {
    page:             $('#page').val(),
    tour_virtual:     $('#tour_virtual').val(),
    order:            $(`${changeForm}_order, #order`).val(),
    purpose:          $('input[name="submit_properties_filter[purpose]"]:checked').val(),
    city:             $(`${changeForm}_city:visible,        ${submitForm}_city:visible        `).val(),
    district:         $(`${changeForm}_district:visible,    ${submitForm}_district:visible    `).val(),
    type:             $(`${changeForm}_type:visible,        ${submitForm}_type:visible        `).val(),
    id_imoview:       $(`${changeForm}_id_imoview:visible,  ${submitForm}_id_imoview:visible  `).val(),
    price_min:        $(`${changeForm}_price_min:visible,   ${submitForm}_price_min:visible   `).val(),
    price_max:        $(`${changeForm}_price_max:visible,   ${submitForm}_price_max:visible   `).val(),
    area_min:         $(`${changeForm}_area_min:visible,    ${submitForm}_area_min:visible    `).val(),
    area_max:         $(`${changeForm}_area_max:visible,    ${submitForm}_area_max:visible    `).val(),
    bedrooms:         $(`${changeForm}:visible ${changeForm}_bedrooms:checked, ${submitForm}:visible ${submitForm}_bedrooms:checked, ${submitForm}_bedrooms:visible`).val(),
    parkings:         $(`${changeForm}:visible ${changeForm}_parkings:checked, ${submitForm}:visible ${submitForm}_parkings:checked `).val(),
    bathrooms:        $(`${changeForm}:visible ${changeForm}_bathrooms:checked, ${submitForm}:visible ${submitForm}_bathrooms:checked `).val(),
    
    characteristics:  $(`${changeForm}:visible ${changeForm}_characteristics:checked, ${submitForm}:visible ${submitForm}_characteristics:checked `).map(
      (index, object) => ( $(object).val() )
    ).toArray().join("-"),
  }
  return data;
}

export const filterChangePrice = () => {
  $('select.price').select2({
    theme: 'bootstrap4', width: '100%',
    ajax: {
      url: '/prices-filters.json',
      processResults: function (data, params) {
        let purposeValue = $("input[type='radio'][name='submit_properties_filter[purpose]']:checked").val() || $('#nav-tab .nav-link.active').data('filter')
        let results = [
          { id: '', text: this.$element.data('includeBlank')}
        ].concat( data[purposeValue] )
        
        
        if (params.term) {
          results = results.filter((el) => (el.id.toString().includes(params.term)))
        }

        return ({
          results: results 
        })
      }
    }
  })
}