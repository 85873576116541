// DOC: https://fancyapps.com/fancybox/3/docs

import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js'

const initAppFancyBox = () => {
 $("[data-fancybox]").fancybox({
   buttons: [
    'zoom',
    'slideShow',
    'thumbs',
    'close'
    //'share',
    //'fullScreen',
    //'download',
   ],
   lang : 'pt-BR',
   protect: true,
   i18n : {
     'pt-BR' : {
      ZOOM : 'Zoom',
      CLOSE : 'Fechar',
      NEXT: 'Próximo',
      PREV: 'Anterior',
      PLAY_START: 'Play',
      FULL_SCREEN: 'Tela cheia'
     }
   }
 });
}

export default initAppFancyBox;