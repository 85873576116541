// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.jQuery = window.$ = require('jquery');
window.Popper = require('popper.js')

require("@rails/ujs").start()
require("turbolinks").start()
// require("channels")

// >>>>>>>>>>  Plugins  <<<<<<<<<<
// Enable Fancybox
// import "../website/plugins/fancybox";

// Bootstrap
import "bootstrap";
// Select2
import "select2";

// Ícones
import "@fortawesome/fontawesome-free/js/all";

// Carousel Slick
import "slick-carousel";

// JS - Toast
import Swal from 'sweetalert2';

// >>>>>>>>>>  Custom CSS  <<<<<<<<<<
require('../website/stylesheets/website.sass')

// >>>>>>>>>>  Custom JS  <<<<<<<<<<
import { initStickyNavbar } from "../website/components/sticky-navbar";
import initMasks from '../website/components/jquery-mask-plugin';
import initAppFancyBox from '../website/components/fancybox';
import mapProperties from '../website/components/map-properties';
import scrollToElement from '../website/components/utils/scroll-to-element';
import modalRealtors from '../website/components/modal-realtors';
import modalTourImage from '../website/components/modal-tour-image';
import linkToAnchorNavegation from '../website/components/utils/link_anchor';

import {
  destroyCarousel,
  initDefaultCarousel,
  initCarouselProperties,
  initCarouselArticles,
  initCarouselImagesBanner
} from "../website/components/slick-carousel"

import {
  filterLaunches
} from "../website/components/filters-launches"

import {
  filterBlog
} from "../website/components/filters-blog"

import {
  filterChangePrice,
  changePropertiesFilter,
  submitPropertiesFilter,
  paginationPropertiesFilter,
  tabPropertiesFilter,
  infinitePaginationPropertiesFilter
} from "../website/components/filters-properties"

// Dispara evento quando carregar a pagina
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()

  // Select2
  $('select.input-select2-with-search').select2({
    theme: 'bootstrap4',
    width: '100%'
  });
  $('select.input-select2').select2({
    theme: 'bootstrap4',
    minimumResultsForSearch: Infinity,
    width: '100%'
  });
  $('select.input-select2-with-creation').select2({
    theme: 'bootstrap4',
    tags: true,
    width: '100%'
  });
  initStickyNavbar( $("#contact-header"), $("#links-header") );
  initMasks();
  initAppFancyBox();

  // Carousel
  initDefaultCarousel();
  initCarouselProperties();
  initCarouselArticles();
  initCarouselImagesBanner();

  // Filter
  filterLaunches();
  filterBlog();

  // Properties
  filterChangePrice();
  changePropertiesFilter();
  submitPropertiesFilter();
  paginationPropertiesFilter();
  tabPropertiesFilter();
  infinitePaginationPropertiesFilter();
  mapProperties();
  
  modalRealtors();
  modalTourImage();
  linkToAnchorNavegation();  
})

document.addEventListener("turbolinks:before-cache", () => {
  // resolve bug do carrossel que nao renderiza quando muda de pagina
  destroyCarousel();
  $('select.input-select2').select2('destroy');
  $('select.input-select2-with-search').select2('destroy');
  $('select.input-select2-with-creation').select2('destroy');
  $('select.price').select2('destroy');
})


window.Swal = Swal;
window.scrollToElement = scrollToElement;
window.paginationPropertiesFilter = paginationPropertiesFilter;
window.infinitePaginationPropertiesFilter = infinitePaginationPropertiesFilter;

