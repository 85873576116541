const modalRealtors = () => {
  $('#realtor-modal').on('show.bs.modal', event => {
    $.ajax({
      url: '/ajax/realtors',
      method: 'get',
      dataType: 'script',
      data: { 
        realtor: $(event.relatedTarget).data('realtor') 
      },
      beforeSend: () => {
        $(event.currentTarget).find('.content-image').attr('src', '');
        $(event.currentTarget).find('.content-creci p').html('');
        $(event.currentTarget).find('.content-name h3').html('');
        $(event.currentTarget).find('.whatsapp p').html('');
        $(event.currentTarget).find('.email p').html('');
        $(event.currentTarget).find('.content-description').html('');
        $(event.currentTarget).find('.form-title').html('');
        $(event.currentTarget).find('#realtor_name').val('');
      },
      success: (data_json) => {
        const data = JSON.parse(data_json);

        $(event.currentTarget).find('.content-image').attr('src', data?.image?.img?.medium);
        $(event.currentTarget).find('.content-creci p').html(`CRECI: ${data?.creci}`);
        $(event.currentTarget).find('.content-name h3').html(data?.name);
        $(event.currentTarget).find('.whatsapp p').html(data?.phone);
        $(event.currentTarget).find('.email p').html(data?.email);
        $(event.currentTarget).find('.content-description').html(data?.description);
        $(event.currentTarget).find('.form-title').html(`ENTRE EM CONTATO COM ${data?.name}`);
        $(event.currentTarget).find('#realtor_name').val(data?.name);
      }
    })
  })
}

export default modalRealtors;