import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const mapProperties = () => {
  if ( $('#map').length != 0 ){
    const mapCoordinates = $('#map').data('coordinates')
    mapboxgl.accessToken = 'pk.eyJ1IjoibGV0eXJzbCIsImEiOiJja212emUyY3gwYTV2Mm5wNmNtNzBqMHhoIn0.ACcMvvpdoBp2xFsxBviCIw';
  
    // Map
    const map = new mapboxgl.Map({
      container: 'map',
      center: mapCoordinates,
      zoom: 13,
      scrollZoom: false,
      style: 'mapbox://styles/mapbox/streets-v11'
    });
  
    // Map Controls
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());
  
    // Markers
    new mapboxgl.Marker({
      color: '#A5001D'
    }).setLngLat(mapCoordinates).addTo(map);
  }
}

export default mapProperties;