export const filterLaunches = () => {
  var form = $("#filter-launches");

  form.find("select").on('change', ajaxLaunches);
  form.find("#filter_launches_search").on('keyup', ajaxLaunches);
}

const ajaxLaunches = (e) => {
  e.preventDefault();
  e.stopPropagation();

  let data = {
    search:  $('#filter_launches_search').val(),
    purpose: $("#filter_launches_purpose").val(),
    order:   $('#filter_launches_order').val(),
  }

  $.ajax({
    url: '/ajax/filter_launches',
    method: 'get',
    dataType: 'script',
    data: data,

    beforeSend:  () => {
      $("#list-launches").hide();
      $("#loader").show();
    },
    success: () => {
      $("#loader").hide();
      $("#list-launches").show();
    }
  })
}