const modalTourImage = () => {
  $('#tour-modal').on('show.bs.modal', event => {
    let iframeElement = `<iframe src="${$(event.relatedTarget).data('iframeSrc')}" class="w-100" title="Tour Virtual Legalize" />`;
    $(event.target).find('.content-iframe').html(iframeElement);
  });
  $('#tour-modal').on('hide.bs.modal', event => {
    $(event.target).find('.content-iframe').html('');
  });
}

export default modalTourImage;